import React from "react";
import styles from "./purchase-banner.module.scss";
import Link from "next/link";
import Image from "next/legacy/image";
import carImage from "./banner-image.png";
import classNames from "classnames";

export default function PurchaseBanner() {
  return (
    <div className={styles.banner}>
      <div className={classNames("container", styles.container)}>
        <div className={styles.header}>
          <div className={styles.imageContainer}>
            <Image alt="" src={carImage} />
          </div>
          <div className={classNames("row", styles.textRow)}>
            <div className="col-auto">
              <Link href="/available-vehicles" legacyBehavior={true}>
                <a className={styles.button}>
                  Interested in buying a car from our inventory?
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
