import {useState, useContext, useRef} from "react";
import ButterCMSContentContext from "../../context/butterCMSContent";
import Image from "next/legacy/image";
import PlayButton from "../../../public/images/partners/playButton.svg";
import VimeoModal from "../../Shared/VimeoModal/VimeoModal";
import ReactPlayer from "react-player/lazy";
import classNames from "classnames";
import s from "./videoSection.module.scss";

export default function VideoSection({className, fromHp = false}) {
  const showMobile = useRef(false);
  const [show, setShow] = useState(false);

  let {video_section} = useContext(ButterCMSContentContext);

  const handleClose = () => setShow(false);
  const handleShow = (isMobileTrigger) => {
    showMobile.current = !!isMobileTrigger;
    setShow(true);
  };

  return (
    <div
      className={classNames(
        className,
        !fromHp ? s.videoSection : s.hpVideoSection
      )}
    >
      <div className={!fromHp ? "container" : ""}>
        {!fromHp && (
          <>
            <div className={s.title}>{video_section.title}</div>
            <div className={s.content}>{video_section.content}</div>
          </>
        )}
        <div className={classNames("d-md-none", s.mobileVideo)}>
          <div className={s.mobileVideoPlayer}>
            <ReactPlayer
              url={video_section.mobile_video_url}
              playing={true}
              autoPlay={true}
              loop={true}
              muted={true}
              controls={false}
              playsinline={true}
              width="100%"
              config={{
                playerVars: {
                  controls: 0,
                },
              }}
            />
          </div>
          <button className={s.playButton} onClick={() => handleShow(true)}>
            <Image src={PlayButton} width={60} height={60} alt="Play video" />
          </button>
        </div>
        <div
          className={classNames(
            "d-none d-md-block",
            s.desktopVideo,
            fromHp ? s.fromHp : ""
          )}
        >
          <button className={s.playButton} onClick={() => handleShow(false)}>
            <Image src={PlayButton} width={80} height={80} alt="Play video" />
          </button>
          <div className={s.desktopVideoPrev}>
            <div className={s.embedContainer}>
              <ReactPlayer
                className={s.emVideo}
                url={video_section.video_url}
                playing={true}
                autoPlay={true}
                loop={true}
                muted={true}
                controls={false}
                playsinline={true}
                config={{
                  playerVars: {
                    controls: 0,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <VimeoModal
        show={show}
        onHide={handleClose}
        video={
          showMobile.current
            ? video_section.mobile_video_url
            : video_section.video_url
        }
      />
    </div>
  );
}
